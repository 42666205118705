<template>
  <div>
    <b-row
      align-h="between"
      align-v="center"
      class="ml-0 pl-0"
    >
      <b-col
        lg="2"
        md="3"
        sm="4"
        xs="12"
      >
        <b-form-group class="mb-1">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("common.perPage")
          }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="4"
      >
        <v-select
          v-model="search"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="categoryOptions"
          label="name"
          :clearable="false"
          class="mb-1 item-selector-title"
          :placeholder="$t('common.select_item')"
        />
      </b-col>
      <b-col
        cols="12"
        lg="6"
        md="6"
      >
        <b-form-group
          label-cols-sm="1"
          label-align-sm="right"
          label-for="filterInput"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              :placeholder="$t('common.search')"
              class="form-control-merge mr-1"
              type="search"
            />
            <b-button
              v-if="isMovil"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              @click="setProducts(save = false)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-button
              v-else
              variant="danger"
              class="mr-1"
              @click="setProducts(save = false)"
            >
              {{ $t("common.cancel") }}
            </b-button>
            <b-button
              v-if="isMovil"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-2"
              @click="setProducts(save = true)"
            >
              <feather-icon icon="SaveIcon" />
            </b-button>
            <b-button
              v-else
              variant="primary"
              @click="setProducts(save = true)"
            >
              {{ $t("common.save") }}
            </b-button>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      ref="selectableTable"
      selectable
      :per-page="perPage"
      :current-page="currentPage"
      :select-mode="selectMode"
      selected-variant=""
      :items="filteredProducts"
      :fields="fields"
      responsive
      :busy="isBusy"
      show-empty
      :filter="filter"
      :filter-included-fields="filterOn"
      :tbody-tr-class="rowClass"
      class="rows-responsive tb-products"
      @row-clicked="onRowSelected"
      @filtered="onFiltered"
    >
      <template #cell(selected)="dataS">
        <template v-if="dataS.item.selected">
          <i class="feather icon-disc primary" />
        </template>

        <template v-else>
          <i class="feather icon-circle" />
        </template>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>

      <template #empty="scope">
        <b-row align-h="center">
          <span>{{ scope.emptyText }}</span>
        </b-row>
      </template>
      <template #cell(name)="cellName">
        <div class="d-flex align-items-center name-cell">
          <b-avatar
            size="lg"
            class="mr-1"
            :src="`${url}/images/${cellName['item'].filename}`"
          />
          <div class="d-flex flex-column">
            <p>
              {{ cellName["item"].name }}
            </p>
            <p class="text-muted">
              {{ cellName["item"].code }}
            </p>
          </div>
        </div>
      </template>

      <template #cell(stock)="cellStock">
        <span
          :style="
            `color:${
              cellStock.item.stock <= 0 || cellStock.item.stock == null
                ? 'red'
                : 'gray'
            }`
          "
        >{{ cellStock.item.stock || "-" }}</span>
      </template>

      <template #cell(units)="cellUnits">
        <b-form-input
          v-if="cellUnits.item.selected"
          v-model="cellUnits.item.units"
          :placeholder="`${$t('stocks.units')}`"
          type="number"
          @input="
            (val) => {
              addUnit(val, cellUnits.item);
            }
          "
        />
      </template>
    </b-table>
    <!-- pagination -->
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows === 0 ? filteredProducts.length : totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>

    <!-- page length -->
    <span
      class="d-none d-md-block"
    >{{ $t("common.displaying") }} {{ perPage * currentPage }}
      {{ $t("common.of") }} {{ filteredProducts.length }}</span>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BCol,
  BRow,
  BSpinner,
  BButton,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { isMobile } from 'mobile-device-detect'
import env from '@/@helpers/envs'

export default {
  components: {
    BTable,
    BAvatar,
    BFormGroup,
    BInputGroup,
    BFormInput,
    vSelect,
    BCol,
    BRow,
    BSpinner,
    BButton,
    BFormSelect,
    BPagination,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    invoiceData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMovil: isMobile,
      perPage: 80,
      pageOptions: [25, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      search: { id: '-1', name: `${this.$i18n.t('products.all')}` },
      filter: null,
      filterOn: [],
      url: env.baseURL,
      fields: [
        { key: 'selected', name: '' },
        { key: 'name', label: 'products.name', sortable: true },
        { key: 'stock', label: 'products.stock', sortable: true },
        { key: 'category', label: 'products.category', sortable: true },
        { key: 'sell_price', label: 'products.sell_price', sortable: true },
        { key: 'units', label: 'stocks.units', sortable: true },
      ],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      selectMode: 'multi',
      selected: [],
      countProducts: 0,
    }
  },
  computed: {
    categoryOptions() {
      const obj = this.$store.state.app.system?.products?.categories
      const map = new Map()
      if (obj) {
        Object.keys(obj).forEach(key => {
          map.set(key, obj[key])
        })
        const array = []
        map.forEach((val, key) => {
          array.push({ id: Number(key), name: val })
        })
        array.unshift({ id: '-1', name: `${this.$i18n.t('products.all')}` })
        return array
      }
      return []
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    isAdmin() {
      return (
        JSON.parse(window.localStorage.userData).role === 'ADMIN'
        || JSON.parse(window.localStorage.userData).role === 'ADMINISTRATION'
      )
    },
    filteredProducts() {
      return this.search.name !== `${this.$i18n.t('products.all')}`
        ? this.data.filter(product => product.category
          ?.toLowerCase()
          .includes(this.search?.name?.toLowerCase()))
        : this.data
    },
  },
  beforeUpdate() {
    this.fieldLanguage()
  },
  mounted() {
    this.fieldLanguage()
    this.countProducts = this.invoiceData.products.length
    this.selected = this.invoiceData.products
    this.$refs.selectableTable.clearSelected()
    this.data.forEach(p => {
      // eslint-disable-next-line no-param-reassign
      p.selected = false
    })
    this.invoiceData.products.forEach(p => {
      const index = this.data.findIndex(
        item => item.name.toLowerCase() === p.name.toLowerCase(),
      )
      if (index !== -1) {
        this.$refs.selectableTable.selectRow(index)
        this.data[index].selected = true
        this.data[index].units = p.units
      }
    })
    this.totalRows = this.filteredProducts.length
  },
  methods: {
    addUnit(val, item) {
      this.selected.forEach(el => {
        if (item.name === el.name) {
          // eslint-disable-next-line no-param-reassign
          el.units = val
        }
      })
    },
    checkForDependencies(val) {
      this.$emit('checkForDependencies', val)
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.selected) {
        // eslint-disable-next-line consistent-return
        return 'bg-warning bg-lighten-5'
      }
    },
    setProducts(save) {
      if (save) {
        this.selected.forEach((select, index) => {
        // eslint-disable-next-line no-const-assign
          this.selected[index] = {
            product_code: select.code || select.product_code,
            name: select.name,
            productId: select.id ?? null,
            price: select.sell_price || select.price,
            units: select.units || 1,
            stock: select.real_stock || 0,
          }
        })
        // eslint-disable-next-line no-self-assign
        this.invoiceData.products = this.selected
        this.invoiceData.products.forEach(el => {
          this.checkForDependencies(el)
        })
      } else {
        // eslint-disable-next-line radix
        const totalRemove = parseFloat(this.selected.length - this.countProducts)
        this.invoiceData.products.splice(this.countProducts, totalRemove)
      }
      this.$emit('closeModal', true)
    },
    fieldLanguage() {
      this.fields.forEach((_, index) => {
        this.fields[index].label = `${this.$i18n.t(this.fields[index].label)}`
      })
    },
    onRowSelected(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
      if (item.selected) {
        this.selected.push(item)
      } else {
        const index = this.selected.findIndex(
          i => i.name.toLowerCase() === item.name.toLowerCase(),
        )
        this.selected.pop(index)
      }
    },
    onFiltered(value) {
      this.totalRows = value.length
      this.currentPage = 1
      this.invoiceData.products.forEach(p => {
        const index = value.findIndex(
          item => item.name.toLowerCase() === p.name.toLowerCase(),
        )
        if (index !== -1) {
          this.$refs.selectableTable.selectRow(index)
          this.filteredProducts[index].selected = true
        }
      })
    },
  },
}
</script>
<style>
.tb-products table td {
  padding: 0.02rem 2rem !important;
}
.name-cell {
  padding: 8px 0 !important;
}
[dir] p {
    margin-top: 0;
    margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
.table-active {
  color: green;
}
</style>
